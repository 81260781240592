<template>
  <div>
    <SeoTemplatePreset>
      <main class="layout-pdf">
        <slot />
      </main>
    </SeoTemplatePreset>
  </div>
</template>

<script setup></script>

<style>
.layout-pdf {
  overflow: hidden;
}
</style>
